import name from './svg/name.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={name} className="App-logo" alt="name" />
        <p>
        soon...
        </p>

      </header>
    </div>
  );
}

export default App;
